import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil"
import { icConnectedState, icFileServerUserState, icSettingsState, icExportDataState } from "./state"
import './UserInfo.scss'

function UserInfo ({ }) {
  const icSettings = useRecoilValue(icSettingsState)
  const icConnected = useRecoilValue(icConnectedState)
  const exportData = useSetRecoilState(icExportDataState)
  const [icFileServerUser, setIcFileServerUser] = useRecoilState(icFileServerUserState)
  if (!icFileServerUser) return null
  const username = icFileServerUser ? <span>{icFileServerUser} <a onClick={() => setIcFileServerUser('')}>x</a></span> : icSettings.id
  return <span className='user-info'>
    {username}
    {icConnected &&
      <a onClick={() => {
        exportData('mine')
      }}>💾</a>
    }
  </span> 
}
export default UserInfo
