import { fromPairs, reduce, groupBy, mapObjIndexed, values, sort, filter, prop, map, join, concat, find, last, propEq, split, pipe, curry, ifElse, identity, always, toPairs } from 'ramda'
import IC from './ic-js/src/IC'

export const getDbId = thing => {
  if (!thing) return false
  if (IC.isIcUrl(thing)) {
    return thing.replace(/^https?:\/\//, '')
  }
  return pipe(
    split('/'),
    last
  )(thing)
}

export const getDbSettings = curry((icSettings, dbId) => {
  let allSettings = [icSettings]
  if (allSettings.links) {
    allSettings = concat(allSettings, allSettings.links)
  }
  return find(propEq('id', dbId), allSettings) || {}
})

export const setStorage = (key, val) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(val))
  } catch (err) {

  }
}

export const getStorage = key => {
  try {
    const val = window.localStorage.getItem(key)
    if (val) {
      return JSON.parse(val)
    }
  } catch (err) {
  }
}

export const icUrl = icAddress => {
  return `${window.location.protocol}//${window.location.host}${window.location.pathname}?ic=${icAddress}`
}

export const pin = async (content, fileName) => {
  const jwt = getStorage('pinataJwt')
  if (!jwt) return
  const formData = new FormData()
  formData.append('file', new Blob([content], { type: "text/ic"}), fileName)
  if (fileName) {
    formData.append('pinataOptions', JSON.stringify({
      wrapWithDirectory: true
    }))
  }
  return fetch('https://api.pinata.cloud/pinning/pinFileToIPFS', {
    method: 'post',
    headers: {
      Authorization: `Bearer ${jwt}`
    },
    body: formData
  })
    .then(res => res.json())
    .then(res => {
      if (fileName) {
        return res.IpfsHash + '/' + fileName
      }
      return prop('IpfsHash', res)
    })
    .catch(err => {
      console.log(err)
    })
}

export const mostActiveSort = sort((a, b) => b.count - a.count)

export const FILTERS = [{
  label: '> 0',
  func: filter(t => t.sum > 0)
}, {
  label: 'All',
  func: identity
}, {
  label: 'Unacknowledged',
  func: filter(t => !t.me)
}, {
  label: 'Acknowledged',
  func: filter(prop('me'))
}]
export const SORTS = [{
  label: 'Most Active',
  func: mostActiveSort
}, {
  label: 'Highest Rated',
  func: pipe(
    sort((a, b) => b.sum - a.sum)
  )
}, {
  label: 'Most Controversial',
  func: sort((a, b) => {
    // this shoud be a percent
    const diff = Math.abs(a.sum) - Math.abs(b.sum)
    if (diff !== 0) return diff
    return b.count - a.count
  })
}]

export const what = what => {
  console.log(what)
  return what
}

export const isSomething = v => v || v === 0

export const copyToClipboard = async str => {
  await navigator.clipboard.writeText(str)
  alert('copied to clipboard')
}

export const countGroupedTags = pipe(
  mapObjIndexed((uniqTags, tag) => {
    return {
      count: uniqTags.length,
      sum: uniqTags.reduce((acc, val) => val.yesNo === '-' ? acc - 1 : acc + 1, 0),
      yes: uniqTags.filter(t => t.yesNo !== '-').length,
      no: uniqTags.filter(t => t.yesNo === '-').length,
      tag
    }
  }),
  values
)

export const cleanAndCountTags = pipe(
  groupBy(prop('from')),
  countGroupedTags
)

export const countAllEdges = curry((allTags, depth, tag) => {
  if (depth === 0) return 0
  const thisTagsChildren = pipe(
    getChildren(tag),
    toPairs,
    filter(([k, v]) => k !== tag),
    fromPairs,
    countGroupedTags
  )(allTags)
  return thisTagsChildren.reduce((acc, val) => acc + val.count, 0) + map(countAllEdges(allTags, depth - 1), thisTagsChildren).reduce((acc, val) => acc + val, 0)
})

// tag is false if you want all
export const getParents = tag => {
  return pipe(
    filter(t => isSomething(tag) ? t.from === tag : true),
    groupBy(prop('to'))
  )
}

// tag is false if you want all
export const getChildren = tag => {
  return pipe(
    filter(t => isSomething(tag) ? t.to === tag : true),
    groupBy(prop('from'))
  )
}

export const isIcUrl = pth => {
  return /^https?:/.test(pth) && /\.ic$/.test(pth)
}
