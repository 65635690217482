import './App.scss'
import {
  RecoilRoot
} from 'recoil'
import ICWrap from './ICWrap'
import Topics from './Topics'
import { useEffect, useState } from 'react'
import {
  HashRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom'
import See from './See'
import DbName from './DbName'
import Sidebar from './Sidebar'
import EntryPoints from './EntryPoints'
import IcFileServerMessage from './IcFileServerMessage'

const getALogo = () => {
  const logos = [
    [['❄️', 'i'], ['🥶', 'c'], ['❄️', 'y']],
    [['👁', 'eye'], ['🌊', 'sea']],
    [['🙋🏾‍♀️', 'aye'], ['👍', 'sí']],
    [['✋🏽', 'I'], ['👀', 'see']],
    [['😮', 'oh,'], ['💡', 'I see!']],
    [['🧊', 'Iccee'], ['🍧', '!!!']],
    [['ℹ️', 'Intelligence'], ['©️', 'Collective']]
  ]
  return logos[Math.floor(Math.random() * logos.length)]
}

function App () {
  const [logo, setLogo] = useState(null)
  useEffect(() => {
    setLogo(getALogo())
  }, [setLogo])
  const logoIcon = logo && logo.map((arr, i) => <a href='/' key={i} title={arr[1]}>{arr[0]}</a>)
  return (
    <RecoilRoot>
      <Router>
        <div className='App'>
          <nav>
            <h1>{logoIcon}</h1>
            <Sidebar />
          </nav>
          <IcFileServerMessage />
          <ICWrap />
          <div className='content'>
            <Switch>
              <Route path='/c/:tag'>
                <See />
              </Route>
              <Route path='/'>
                <EntryPoints />
              </Route>
            </Switch>
          </div>
        </div>
      </Router>
    </RecoilRoot>
  )
}

export default App
