import { replace, append, find, last, path, propEq, split, pipe } from 'ramda'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { icAddTagState, icConnectedState, icSettingsState } from './state'
import TagInput from './TagInput'
import './DBInfo.scss'
import { getDbId, getStorage, icUrl, isIcUrl } from './lib/utils'
import { useState } from 'react'

function DBInfo ({ connectIC }) {
  const icAddress = useRecoilValue(icConnectedState)
  const icSetting = useRecoilValue(icSettingsState)
  const setAddTag = useSetRecoilState(icAddTagState)
  const [showTemplate, setShowTemplate] = useState(false)
  if (!icAddress) {
    let more = null
    const dbs = getStorage('dbs')
    if (dbs) {
      more = [
        <p key='create-one'>It looks like you've been here before. Here's are the ones you've accessed in the past</p>, 
        ...dbs.map(db => <a key={db.address} href={`${icUrl(db.address)}`}>{getDbId(db.address)}</a>)
      ]
    }
    let create = <>
      <li>
        <a onClick={() => setShowTemplate(true)} >Create a brand new one</a>. It'll only live in your browser, but you can send someone else a link and they'll be able to collabotate with you.
      </li>
    </>
    if (showTemplate) {
      create = <div className='flex'>
        <a className='plus open' onClick={() => setShowTemplate(false)}>+</a>
        <TagInput options={[]} onChange={theName => {
          if (isIcUrl(theName)) {
            window.location.search = `?ic=${theName}`
          } else {
            connectIC(false, theName)
          }
        }} placeholder='Name your group' />
      </div>      
      more = null
    }
    return <div className='create-db'>
      <h1>Welcome to IC!</h1>
      <p></p>
      <ul>
        {create}
      </ul>
      {more}
    </div>
  }
  return null
}

export default DBInfo
