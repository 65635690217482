import Select from 'react-select'
import { useRecoilValue } from 'recoil'
import { icIsesState, icSettingsState, icTagsState } from './state'
import { useEffect, useRef, useState } from 'react'
import { filter, find, map, propEq, test } from 'ramda'
import './TagInput.scss'
import classNames from 'classnames'
import IC from './lib/ic-js/src/IC'

function TagInput ({ className, placeholder, onChange, options, ignoreLowercase }) {
  const icTags = useRecoilValue(icTagsState)
  const icSettings = useRecoilValue(icSettingsState)
  const ises = useRecoilValue(icIsesState)
  const ref = useRef()
  const [didYouMean, setDidYouMean] = useState(false)
  const [value, setValue] = useState('')
  const toLabels = map(obj => ({ label: obj.tag, value: obj.tag }))
  let opts = options || toLabels(icTags.filter(t => !/^[:\/]/.test(t.tag)))
  if (didYouMean) {
    const cleanIses = filter(tag => tag.to === didYouMean, ises).map(tag => ({ tag: tag.from.replace(/^:is:/, '') }))
    opts = toLabels(cleanIses)
  }
  if (!find(propEq('value', value), opts) && value !== '') {
    opts.unshift({
      label: didYouMean ? `Yes, I meant "${value}"` : value,
      value
    })
  }
  // console.log(opts);
  useEffect(() => {
    if (ref.current) {
      ref.current.focus()
    }
  }, [])
  return <Select
    options={opts}
    ref={ref}
    value={value}
    autoFocus
    classNamePrefix='tag-input'
    className={classNames('tag-input', className, { error: didYouMean })}
    placeholder={placeholder}
    onInputChange={origVal => {
      let val = IC.clean(origVal)
      if (icSettings.lowercase && !ignoreLowercase) {
        val = val.toLowerCase()
      }
      setValue(val)
    }}
    onChange={val => {
      const isTag = find(propEq('to', val.value), ises)
      // if (isTag) {
      //   setDidYouMean(val.value)
      //   console.log(isTag);
      //   return
      // }
      if (onChange) {
        onChange(val.value)
      }
      setValue('')
      ref.current.focus()
    }}
  />
}

export default TagInput
