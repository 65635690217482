import './EntryPoints.scss'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { breadcrumbState, filterIState, sortIState, icTagsState, icSettingsState, icConnectedState, icDataState } from './state'
import Tag from './Tag'
import { mostActiveSort, FILTERS, SORTS, cleanAndCountTags, getParents, countGroupedTags, countAllEdges } from './lib/utils'
import { map, keys, not, filter, sort, pipe, slice } from 'ramda'
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import TagInput from './TagInput'
import classNames from 'classnames'

function EntryPoints () {
  const icSettings = useRecoilValue(icSettingsState)
  const icTags = useRecoilValue(icTagsState)
  const sortI = useRecoilValue(sortIState)
  const filterI = useRecoilValue(filterIState)
  const icAddress = useRecoilValue(icConnectedState)
  const icData = useRecoilValue(icDataState)
  const setBreadcrumb = useSetRecoilState(breadcrumbState)
  const [open, setOpen] = useState(false)
  const history = useHistory()
  const groups = pipe(
    getParents(false),
    keys,
    filter(t => t !== icSettings.id),
    map(tag => ({ tag, count: countAllEdges(icData, 3) })),
    sort((a, b) => b.count - a.count)
  )(icData)
  const tagsToShow = icSettings.entry ? icSettings.entry.map(tag => ({ tag })) : pipe(
    slice(0, 40)
  )(groups)
  useEffect(() => {
    setBreadcrumb([])
  })
  if (!icAddress) {
    return null
  }
  let show = (
    <ul className='tags'>
      {tagsToShow.map(tag => <li key={tag.tag}><Tag tag={tag} link /></li>)}
    </ul>
  )
  if (tagsToShow.length < 1) {
    show = (
      <div className='no-tags'>
        <p>There's nothing in here yet. Type anything and hit enter to get started</p>
      </div>
    )
  }
  return (
    <div className='entry-points'>
      <div className='centered add-something'>
        <a className={classNames('plus', { open: open })} onClick={() => setOpen(not)}>+</a>
        {open &&
          <TagInput
            placeholder='Create a new group/list/topic/tag/thing...'
            onChange={tag => {
              history.push(`/c/${encodeURIComponent(tag)}`)
            }}
          />
        }
      </div>
      {show}
    </div>
  )
}
export default EntryPoints
