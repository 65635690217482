import { filter, length, pipe, pluck, propEq, toPairs, uniq } from "ramda"
import { Link } from "react-router-dom"
import { useRecoilValue } from "recoil"
import DbAvatar from "./DbAvatar"
import Stats from "./Stats"
import { getDbId, icUrl } from "./lib/utils"
import { icConnectedState, icDataState, icSettingsState } from "./state"

function DbName ({ link }) {
  const icAddress = useRecoilValue(icConnectedState)
  const icSettings = useRecoilValue(icSettingsState)
  const icData = useRecoilValue(icDataState)
  if (!icAddress) return null
  const users = pipe(
    pluck('dId'),
    uniq
  )(icData)
  let linksHtml
  if (icSettings.links) {
    const links = toPairs(icSettings.links)
    linksHtml = <div className='links'>
      {links.map(arr => <a key={`${arr[0]}-${arr[1].data}`} href={icUrl(arr[0])}>
        <DbAvatar
          name={getDbId(arr[0])}
          loading={arr[1].data === 'loading'}
          size={16}
        />
      </a>)}
    </div>
  }
  return <div className='db-name-wrap'>
    <Link className='db-name' to={link || `/c/${getDbId(icAddress)}`}>
      <DbAvatar size={16} />
      <span>
        {icSettings.name || getDbId(icAddress)} 
      </span>
    </Link>
    <div className='stats-wrap'>
      {link && <Link to={`/c/${getDbId(icAddress)}`} className='fas fa-cog'/>}
      <Stats data={{
        users: users.length,
        yes: pipe(filter(propEq('yesNo', '+')), length)(icData),
        no: pipe(filter(propEq('yesNo', '-')), length)(icData)
      }} />
    </div>
    {linksHtml}
  </div>
}
export default DbName
