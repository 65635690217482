import { atom, selector } from 'recoil'
import { curry, concat, length, pluck, filter, pipe, uniq, groupBy, prop, mapObjIndexed, values, map, flatten, groupWith, equals, sort, identity, uniqBy, fromPairs, reverse, uniqWith, toPairs, keys, reduce, append, find, findIndex } from 'ramda'
import { getDbId, getStorage, what } from '../lib/utils'

export const icRawDataState = atom({
  key: 'icRawDataState',
  default: []
})


export const icLinksDataState = atom({
  key: 'icLinksDataState',
  default: {}
})

export const icDataState = selector({
  key: 'icDataState',
  get: ({ get }) => {
    const icLinkData = get(icLinksDataState)
    const linkedData = pipe(
      values,
      filter(thing => Array.isArray(thing)),
      flatten
    )(icLinkData)
    return pipe(
      concat(linkedData),
      reverse, // reverse so uniq will take the latest
      uniqWith((a, b) => a.dId === b.dId && a.from === b.from && a.to === b.to),
      reverse // put it back so newest are at the end
    )(get(icRawDataState))
  }
})
export const icConnectedState = atom({
  key: 'icConnectedState',
  default: false
})

export const ipfsState = atom({
  key: 'ipfsState',
  default: {}
})

export const icStorageState = atom({
  key: 'icStorageState',
  default: false
})

export const icAddTagState = atom({
  key: 'icAddTagState',
  default: []
})

export const icExportDataState = atom({
  key: 'icExportDataState',
  default: false
})

export const icTopics = selector({
  key: 'icTopics',
  get: ({ get }) => {
    const data = get(icDataState)
    const icAddress = get(icConnectedState)
    const icSettings = get(icSettingsState)
    const dbId = getDbId(icAddress)
    let linkedDbs = []
    if (icSettings.links) {
      linkedDbs = pipe(
        keys,
        map(getDbId)
      )(icSettings.links)
    }
    return pipe(
      filter(t => {
        return t.to !== dbId && !linkedDbs.includes(t.to)
      }),
      groupBy(prop('to')),
      mapObjIndexed((val, to) => {
        return {
          to,
          count: length(val)
        }
      }),
      values
    )(data)
  }
})

export const icTagsState = selector({
  key: 'icTags',
  get: ({ get }) => {
    const data = get(icDataState)
    return pipe(
      map(t => [t.to, t.from]),
      flatten,
      groupBy(identity),
      mapObjIndexed((value, key) => ({ tag: key, count: value.length })),
      values,
      sort((a, b) => b.length - a.length)
    )(data)
  }
})

const addAddressAndId = curry((addr, obj) => {
  return {
    ...obj,
    address: addr,
    id: getDbId(addr)
  }
})
export const icSettingsState = selector({
  key: 'icSettingsState',
  get: ({ get }) => {
    const data = get(icDataState)
    const icLinksData = get(icLinksDataState)
    const icAddress = get(icConnectedState)
    const regex = /^\|([^ |]+)\|/
    const settings = pipe(
      filter(tag => regex.test(tag.from) && tag.yesNo === '+'),
      uniqBy(prop('from')),
      map(tag => {
        let key = tag.from.match(regex)[1]
        let val = tag.from.replace(regex, '')
        const isArray = /\[\]$/.test(key)
        if (isArray) {
          key = key.replace(/\[\]$/, '')
          val = [val]
        }
        return [key, val]
      }),
      reduce((acc, arr) => {
        let ret = arr
        if (Array.isArray(arr[1])) {
          const exisitingArrI = findIndex(a => arr[0] === a[0], acc)
          if (exisitingArrI > -1) {
            acc[exisitingArrI] = [arr[0], append(arr[1][0], acc[exisitingArrI][1])]
            return acc
          }
        }
        return append(ret, acc)
      }, []),
      fromPairs,
      addAddressAndId(icAddress)
    )(data)
    const links = toPairs(icLinksData)
    if (links.length > 0) {
      settings.links = {}
      links.forEach(arr => {
        settings.links[arr[0]] = addAddressAndId(arr[0], {
          data: arr[1] === 'loading' ? 'loading' : arr[1].length
        })
      })
    }
    return settings
  }
})

export const icIsesState = selector({
  key: 'icIsesState',
  get: ({ get }) => {
    const data = get(icDataState)
    const regex = /^:is:/
    const ises = pipe(
      filter(tag => regex.test(tag.from)),
      groupBy(tag => tag.to + tag.from),
      mapObjIndexed((uniqTags, tag) => {
        return {
          count: uniqTags.length,
          sum: uniqTags.reduce((acc, val) => val.yesNo === '-' ? acc - 1 : acc + 1, 0),
          yes: uniqTags.filter(t => t.yesNo !== '-').length,
          from: uniqTags[0].from,
          to: uniqTags[0].to
        }
      }),
      values,
      filter(tag => tag.sum > 0)
    )(data)
    return ises
  }
})

export const sortIState = atom({
  key: 'sortIState',
  default: 0
})

export const filterIState = atom({
  key: 'filterIState',
  default: 1
})

export const lensState = atom({
  key: 'lensState',
  default: false
})

export const breadcrumbState = atom({
  key: 'breadcrumbState',
  default: []
})

export const pinataJwtState = atom({
  key: 'pinataJwtState',
  default: getStorage('pinataJwt')
})

export const icFileServerUrlState = atom({
  key: 'icFileServerUrlState',
  default: getStorage('icFileServerUrl')
})

export const icFileServerUserState = atom({
  key: 'icFileServerUserState',
  default: getStorage('icFileServerUser')
})
