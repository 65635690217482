import { sort, append, not } from 'ramda'
import { useEffect, useState } from 'react'
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import { icAddTagState, icConnectedState, icDataState, icTopics } from './state'
import TagInput from './TagInput'
import classNames from 'classnames'
import './Topics.scss'
import { Link } from 'react-router-dom'

function Topics ({ }) {
  const [topic, setTopic] = useState('')
  const [open, setOpen] = useState(false)
  const [tag, setTag] = useState('')
  const topics = useRecoilValue(icTopics)
  const setAddTag = useSetRecoilState(icAddTagState)
  const icConnected = useRecoilValue(icConnectedState)
  const onSubmit = evt => {
    if (evt) {
      evt.preventDefault()
    }
    if (topic !== '' && tag !== '') {
      setAddTag(append({
        to: topic,
        from: tag
      }))
      setTopic('')
      setTag('')
    }
  }
  useEffect(() => {
    if (topic !== '' && tag !== '') {
      onSubmit()
    }
  }, [topic, tag])
  if (!icConnected) {
    return null
  }
  let show
  if (open) {
    show = <TagInput placeholder='Thing' onChange={setTopic} />
  }
  if (topic !== '') {
    show = <div>
      <h3>{topic}</h3>
      <TagInput placeholder='Tag' onChange={tag => {
        setTag(tag)
      }} /><br />
      <input type='submit' value='Create' />
    </div>

  }
  return <div className={classNames('topics', { open })}>
    <h3>Entry Points <a className='plus' onClick={() => setOpen(not)}>+</a></h3>
    <form onSubmit={onSubmit} className='add-topic'>
      {show}
    </form>
    <ul>
      {sort((a, b) => b.count - a.count, topics).map(t => <li key={t.to}><Link to={`/c/${encodeURIComponent(t.to)}`}>{t.to}</Link> - {t.count}</li>)}
    </ul>
  </div>
}

export default Topics
