import './Sidebar.scss'
import { useState } from 'react'
import IpfsInfo from './IpfsInfo'
import UserInfo from './UserInfo'
import DbName from './DbName'
import classNames from 'classnames'
import { useRecoilState, useRecoilValue } from 'recoil'
import { breadcrumbState, filterIState, icDataState, ipfsState, lensState, sortIState } from './state'
import { SORTS, FILTERS } from './lib/utils'
import TagInput from './TagInput'
import Tag from './Tag'
import { Link, useHistory } from 'react-router-dom'
import { sort } from 'ramda'
import DbAvatar from './DbAvatar'
import LensStats from './LensStats'
import { useLocation } from 'react-router-dom'

function Sidebar () {
  const [filterI, setFilterI] = useRecoilState(filterIState)
  const [sortI, setSortI] = useRecoilState(sortIState)
  const data = useRecoilValue(icDataState)
  const [lens, setLens] = useRecoilState(lensState)
  const ipfs = useRecoilValue(ipfsState)
  const history = useHistory()
  const location = useLocation()
  const bc = useRecoilValue(breadcrumbState)
  const [search, setSearch] = useState(false)
  let tab = <>
    <DbName />
    <div>
      Recent:
      <ul className='recent'>
        {sort((a, b) => a.time - b.time, data).slice(-10).reverse().map(t => <li key={t.from + t.to + t.time}>
          <div className='from-to'>
            <Link to={`/c/${encodeURIComponent(t.to)}`}>{t.to}</Link>
            <span className='yes-no'>{t.yesNo === '-' ? ' x ' : ' • '}</span>
            <Link to={`/c/${encodeURIComponent(t.from)}`}>{t.from}</Link>
          </div>
        </li>)}
      </ul>
    </div>
  </>
  if (lens) {
    tab = <div>
      <LensStats />
    </div>
  }
  return <div className='sidebar'>
    <DbName link='/' />
    <div className='breadcrumb'>
      {bc.map(b => <Link key={b} to={`/c/${encodeURIComponent(b)}`}>{b}</Link>)}
   </div>
    <div className='filters'>
      <div className={classNames('search', { open: search })}>
        <a className="fas fa-search" onClick={() => setSearch(!search)} />
        <TagInput
          placeholder='Search'
          onChange={tag => {
            history.push(`/c/${encodeURIComponent(tag)}`)
          }} />
      </div>
      {/* <div>
        Sort:
        <select value={sortI} onChange={evt => setSortI(evt.target.value)}>
          {SORTS.map((f, i) => <option key={f.label} value={i}>{f.label}</option>)}
        </select>
      </div>
      <div>
        Filter:
        <select value={filterI} onChange={evt => setFilterI(evt.target.value)}>
          {FILTERS.map((f, i) => <option key={f.label} value={i}>{f.label}</option>)}
        </select>
      </div> */}
    </div>
    {/* <div className='tab-wrap'>
      <div className='tabs'>
        <a className={classNames({ active: !lens })} onClick={() => setLens(false)}><DbAvatar /></a>
        <a className={classNames('lens', { active: lens })} onClick={() => setLens([ipfs.id])}>👁</a>
      </div>
      {tab}
    </div> */}
    <div className='bottom'>
      <IpfsInfo />
      <UserInfo />
    </div>
  </div>
}
export default Sidebar
