import { useEffect, useRef } from 'react'
import { filter, last, mergeLeft, omit, prop, uniqBy } from 'ramda'
import {
  useRecoilState, useRecoilValue, useSetRecoilState
} from 'recoil'
import {
  icAddTagState,
  icConnectedState,
  icDataState,
  icExportDataState,
  icLinksDataState,
  icRawDataState,
  ipfsState,
  pinataJwtState,
  icFileServerUrlState,
  icFileServerUserState,
  icStorageState,
  icSettingsState
} from './state'
import DBInfo from './DBInfo'
import { copyToClipboard, getDbId, getStorage, pin, setStorage, isIcUrl, icUrl } from './lib/utils'
import IcOrbitDb from './lib/ic-js/src/IcOrbitDb'
import IC from './lib/ic-js/src/IC'
const IPFS = require('ipfs')
const isIPFS = require('is-ipfs')
const search = new URLSearchParams(document.location.search.substring(1))

const isLinkedIc = pth => {
  return IcOrbitDb.isValidAddress(pth) || isIPFS.path(pth) || isIcUrl(pth)
}


function ICWrap () {
  const ipfs = useRef()
  const ic = useRef()
  const icLinks = useRef()
  const setData = useSetRecoilState(icRawDataState)
  const icData = useRecoilValue(icDataState)
  const [addTags, setAddTags] = useRecoilState(icAddTagState)
  const [icAddress, setIcConnected] = useRecoilState(icConnectedState)
  const [ipfsInternal, setIpfs] = useRecoilState(ipfsState)
  const [icStorage, setIcStorage] = useRecoilState(icStorageState)
  const [icLinksData, setIcLinksData] = useRecoilState(icLinksDataState)
  const [exportDataKey, exportData] = useRecoilState(icExportDataState)
  const pinataJwt = useRecoilValue(pinataJwtState)
  const icFileServerUrl = useRecoilValue(icFileServerUrlState)
  const icFileServerUser = useRecoilValue(icFileServerUserState)
  const icSettings = useRecoilValue(icSettingsState)

  const isUrl = icStorage === 'ic-file-server'

  const createIC = async (ipfs, addr, name) => {
    if (isIcUrl(addr)) {
      const ic = new IC()
      // this is my IC so we dont want to treat it as an external IC
      const regex = new RegExp(`${icFileServerUrl.replace(/\/ic$/, '')}/${icFileServerUser}/`)
      if (regex.test(addr)) {
        const str = await fetch(addr).then(res => res.text())
        await ic.import(str.replace('_\n', `_${ic.id}\n`))
      // an external IC
      } else {
        await ic.import(addr)
      }
      return ic
    } else {
      const args = {
        ipfs,
        name
      }
      if (addr) {
        args.orbitdb = { db: addr }
      }
      return IcOrbitDb.create(args)
    }
  }

  const doIpfs = async () => {
    icLinks.current = {}
    ipfs.current = await IPFS.create({
      repo: 'test',
      start: true,
      preload: {
        enabled: false
      },
      EXPERIMENTAL: {
        pubsub: true
      },
      config: {
        relay: {
          enabled: true,
          hop: {
            enabled: true
          }
        },
        Addresses: {
          Swarm: [
            // Use IPFS dev signal server
            // '/dns4/star-signal.cloud.ipfs.team/wss/p2p-webrtc-star',
            // '/dns4/ws-star.discovery.libp2p.io/tcp/443/wss/p2p-websocket-star',
            // Use IPFS dev webrtc signal server
            '/dns4/radiant-woodland-43627.herokuapp.com/tcp/443/wss/p2p-webrtc-star/'
            // '/dns4/wrtc-star1.par.dwebops.pub/tcp/443/wss/p2p-webrtc-star/',
            // '/dns4/wrtc-star2.sjc.dwebops.pub/tcp/443/wss/p2p-webrtc-star/',
            // '/dns4/webrtc-star.discovery.libp2p.io/tcp/443/wss/p2p-webrtc-star/'
            // Use local signal server
            // '/ip4/0.0.0.0/tcp/9090/wss/p2p-webrtc-star',
          ]
        }
      }
    })
    window.ipfs = ipfs.current
    console.log('IPFS node is ready')
    const { id } = await ipfs.current.id()
    setIpfs(mergeLeft({ id }))
    // connect to our peer
    ipfs.current.swarm.connect('/dns4/ipfs.aye.si/tcp/4002/wss/p2p/12D3KooWPUj2txDuBWr6xGEn9AaYhdWWa2ABFfUYVZoVKotcfwJZ')

    ipfs.current.libp2p.connectionManager.on('peer:connect', async function (peer) {
      // console.log(peer.remotePeer.toString());
      const peers = await ipfs.current.swarm.peers()
      setIpfs(mergeLeft({ peers }))
    })
  }

  // init
  useEffect(() => {
    async function go () {
      if (ipfs.current) return
      if (search.get('ic')) {
        connectIC(search.get('ic'))
      }
    }
    go()
  }, [setData])

  // create tags
  useEffect(() => {
    if (addTags.length > 0) {
      const tag = last(addTags)
      ic.current.tag(tag.to, tag.from, tag.yesNo)
      setAddTags(addTags.slice(0, -1))
    }
  }, [addTags])

  // linked dbs
  useEffect(() => {
    if (!icAddress || !ipfsInternal.id || isUrl) return

    // orbitdb stuff
    const dbId = getDbId(icAddress)
    const newLinkAddrs = icData
      .filter(t => t.to === dbId && isLinkedIc(t.from) && t.dId === ipfsInternal.id && t.yesNo === '+')
      .filter(t => !icLinks.current[t.from])
      .map(prop('from'))
    const linkAddrsToRemove = icData
      .filter(t => t.to === dbId && isLinkedIc(t.from) && t.dId === ipfsInternal.id && t.yesNo === '-')
      .filter(t => icLinks.current[t.from])
      .map(prop('from'))
    newLinkAddrs.forEach(async addr => {
      icLinks.current[addr] = true // otherwise you get an endless loop
      setIcLinksData(st8 => {
        const obj = {}
        obj[addr] = 'loading'
        return mergeLeft(obj, st8)
      })
      // load an external ic
      if (IcOrbitDb.isValidAddress(addr)) {
        icLinks.current[addr] = await createIC(ipfs.current, addr)
        // create a local db for this ic import file
      } else if (isIPFS.path(addr)) {
        icLinks.current[addr] = await createIC(ipfs.current)
        await icLinks.current[addr].import(addr)
      } else if (isIcUrl(addr)) {
        icLinks.current[addr] = await createIC(ipfs.current, addr)
      }
      icLinks.current[addr].on('data', data => {
        setIcLinksData(st8 => {
          const obj = {}
          obj[addr] = data
          return mergeLeft(obj, st8)
        })
      })
      icLinks.current[addr].load()
    })
    linkAddrsToRemove.forEach(addr => {
      delete icLinks.current[addr]
      setIcLinksData(st8 => {
        return omit([addr], st8)
      })
    })
  }, [icData, icAddress, ipfsInternal])

  // export data
  useEffect(() => {
    if (!exportDataKey) return
    const go = async () => {
      const fileName = isUrl ? last(icSettings.id.split('/')) : ic.current.db().address.toString().replace(/^\//, '').replace(/\//g, '-') + '.ic'
      if (exportDataKey === 'mine') {
        if (isUrl) {
          const icStr = `${icAddress}\n` + ic.current.export(filter(t => t.dId === ipfsInternal.id))
          console.log(icStr)
          const formData = new FormData()
          formData.append(icFileServerUser, new Blob([icStr], { type: 'text/ic' }), fileName)
          formData.append(fileName, fileName)
          // const server = 'http://localhost:3002'
          const server = icFileServerUrl
          const res = await fetch(server, {
            method: 'POST',
            body: formData
          })
          copyToClipboard(icUrl(`${server.replace(/\/ic$$/, '')}/${icFileServerUser}/${fileName}`))
        } else {
          const cid = await ic.current.exportToIpfs(filter(t => t.dId === ipfsInternal.id))
          copyToClipboard('/ipfs/' + cid.path)
        }
      } else if (exportDataKey === 'all') {
        const cid = await ic.current.exportToIpfs({
          add: content => {
            pin(content, fileName)
          }
        })
        copyToClipboard('http://ipfs.aye.si/ipfs/' + cid.path)
      } else if (exportDataKey === 'all-file') {
        const a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'
        const blob = new Blob([ic.current.export()], { type: 'plain/text' })
        const url = window.URL.createObjectURL(blob)
        a.href = url
        a.target = '_blank'
        a.download = fileName
        a.click()
        window.URL.revokeObjectURL(url)
      }
      exportData(false)
    }
    go()
  }, [exportDataKey])

  // pinata jwt
  useEffect(() => {
    setStorage('pinataJwt', pinataJwt)
  }, [pinataJwt])

  // ic file server url
  useEffect(() => {
    setStorage('icFileServerUrl', icFileServerUrl)
  }, [icFileServerUrl])

  // ic file server username
  useEffect(() => {
    setStorage('icFileServerUser', icFileServerUser)
  }, [icFileServerUser])

  const connectIC = async (addr, name) => {
    // url
    if (isIcUrl(addr)) {
      ic.current = await createIC(ipfs.current, addr, name)
      ic.current.on('data', setData)
      setIcStorage('ic-file-server')
      setData(ic.current.all())
      setIpfs(mergeLeft({ id: ic.current.id, mockIpfs: true }))
      setIcConnected(addr)
      const obj = {}
      ic.current.externalIcs().forEach(ic => {
        obj[ic] = []
      })
      setIcLinksData(obj)
    // IPFS
    } else {
      await doIpfs()
      setIcStorage('ipfs')
      ic.current = await createIC(ipfs.current, addr, name)
      ic.current.on('data', setData)
      await ic.current.load()
      setIcConnected(ic.current.db().address.toString())
      // save it for later
      const dbs = getStorage('dbs') || []
      dbs.push({ address: ic.current.db().address.toString() })
      setStorage('dbs', uniqBy(prop('address'), dbs))
  
      // first time
      if (!addr) {
        console.log('adding to public db log')
        const publicDb = await ic.current.orbitdb.open('/orbitdb/zdpuAxw9AWTHdsnTgRr6GHR85kT4ZLMHaASrcqpvv8pVsuphD/aye.si')
        const hash = await publicDb.add({
          address: ic.current.db().address.toString(),
          created: Date.now()
        })
        console.log(hash)
      }
    }
  }
  return (
    <DBInfo connectIC={connectIC} />
  )
}

export default ICWrap
