import './Tag.scss'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { ReactComponent as X } from './assets/x.svg'
import { isSomething } from './lib/utils'
import Stats from './Stats'

function Tag ({ tag, link, onYes, onNo }) {
  const content = <span className='tag-tag'>{tag.tag}</span>
  return <div className={classNames('tag', { yes: tag.me === '+', no: tag.me === '-', ack: !!tag.me })}>
    <div className='top-wrap'>
      {onYes &&
        <a className='yes' onClick={() => {
          if (tag.me === '+') return
          onYes(tag)
        }}><i /></a>
      }
      {link
        ? <Link className='content' to={`/c/${encodeURIComponent(tag.tag)}`}>
          {content}
        </Link>
        : <span className='content'>{content}</span>
      }
      {onNo &&
        <a className='no' onClick={() => {
          if (tag.me === '-') return
          onNo(tag)
        }}><X /></a>
      }
    </div>
    <div className='stats-wrap'>
      <Stats
        data={Object.assign({
          users: tag.count,
          percent: Math.floor(tag.yes / tag.count * 100)
        }, tag)}
      />
    </div>
  </div>
}

export default Tag
