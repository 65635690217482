import classNames from 'classnames'
import { without, append, filter, find, groupBy, identity, last, mapObjIndexed, not, pipe, prop, propEq, reverse, sort, uniqBy, values, takeLast } from 'ramda'
import { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil'
import DbAvatar from './DbAvatar'
import { SORTS, FILTERS, copyToClipboard, icUrl } from './lib/utils'
import './See.scss'
import { breadcrumbState, icDataState, ipfsState, icAddTagState, icSettingsState, filterIState, sortIState, icExportDataState, pinataJwtState, icConnectedState } from './state'
import TagInput from './TagInput'
import Tag from './Tag'

function See () {
  let { tag } = useParams()
  tag = decodeURIComponent(tag)
  const [breadcrumb, setBreadcrumb] = useRecoilState(breadcrumbState)
  useEffect(() => {
    setBreadcrumb(pipe(
      without([tag]),
      append(tag),
      // takeLast(3)
    ))
  }, [setBreadcrumb, tag])
  const [pinataJwt, setPinataJwt] = useRecoilState(pinataJwtState)
  const exportData = useSetRecoilState(icExportDataState)
  const setAddTag = useSetRecoilState(icAddTagState)
  const icData = useRecoilValue(icDataState)
  const ipfs = useRecoilValue(ipfsState)
  const icAddress = useRecoilValue(icConnectedState)
  const icSettings = useRecoilValue(icSettingsState)
  const filterI = useRecoilValue(filterIState)
  const sortI = useRecoilValue(sortIState)
  const [pj, setPj] = useState('')
  const countFilterAndSort = pipe(
    mapObjIndexed((uniqTags, tag) => {
      return {
        count: uniqTags.length,
        sum: uniqTags.reduce((acc, val) => val.yesNo === '-' ? acc - 1 : acc + 1, 0),
        yes: uniqTags.filter(t => t.yesNo !== '-').length,
        no: uniqTags.filter(t => t.yesNo === '-').length,
        me: (find(propEq('dId', ipfs.id), uniqTags) || {}).yesNo,
        tag
      }
    }),
    values,
    FILTERS[filterI].func,
    SORTS[sortI].func
  )
  const tags = pipe(
    filter(t => t.to === tag),
    groupBy(prop('from')),
    countFilterAndSort
  )(icData)
  const groups = pipe(
    filter(t => t.from === tag),
    groupBy(prop('to')),
    countFilterAndSort,
    filter(t => t.sum > 0)
  )(icData)
  const [open, setOpen] = useState(false)
  const [groupsOpen, setGroupsOpen] = useState(false)
  if (!icAddress) {
    return null
  }
  let desc
  let title = tag
  let ignoreLowercase = false
  // settings page
  if (tag === icSettings.id) {
    ignoreLowercase = true
    title = (
      <>
        <DbAvatar size={50} name={tag} />
        <span>{tag}</span>
      </>
    )
    desc = (
      <>
        {icSettings.help === 'on' && <p className='help'>
          This is the starting point for your IC, but it's doesn't have to be what it's all about.  On aye.si we use it to keep track of your settings. This is also where we provide a way to export/share your IC. <br/><br/>To try a setting, click the "X" next to "|help|on"
        </p>}
        <div className='export'>
          <p>
            <h2>Sharing / Collaborating / Exporting</h2>
            <ul>
              <li>
                Sharable link: <input readOnly type='text' value={icUrl(icSettings.address)} />
                <a onClick={() => {
                  copyToClipboard(icUrl(icSettings.address))
                }} className='fa fa-copy' />
              </li>
              <li>
                IC ID others can use to import your data:
                <input type="text" readOnly value={icSettings.address} />
                <a onClick={async () => {
                  copyToClipboard(icSettings.address)
                }} className='fa fa-copy' />
              </li>
              <li>
                Export IC to IPFS:
                <a
                  onClick={() => {
                    exportData('all')
                  }}
                  className='fa fa-cloud'
                />
                {!pinataJwt && <div className='pinata'>
                  <input type='text' value={pj} onChange={e => setPj(e.target.value)} placeholder='Pinata JWT' />
                  <button className='btn btn-primary' onClick={e => setPinataJwt(pj)}>Save</button>
                </div>}
              </li>
              <li>
                Export IC to File:
                <a
                  onClick={() => {
                    exportData('all-file')
                  }}
                  className='fa fa-file'
                />
              </li>
            </ul>
          </p>
        </div>
      </>
    )
  }
  const depth1 = icSettings.depth && parseInt(icSettings.depth, 10) === 1

  return (
    <div className='see'>
      {groups.length > 0 && <div className={classNames('groups-wrap', { open: groupsOpen })}>
        <label>Groups: <a className={classNames('plus', { open: groupsOpen } )}onClick={() => setGroupsOpen(not)}>+</a></label>
        {groupsOpen && <TagInput
          placeholder={`Add "${tag}" to a group`}
          ignoreLowercase={ignoreLowercase}
          onChange={to => {
            setAddTag(append({
              from: tag,
              to
            }))
            setGroupsOpen(false)
          }}
        />}
        <div className='groups'>
          <ul>
            {groups.map(g => {
              return <li key={`group-${g.tag}`}><Tag tag={g} link /></li>
            })}
          </ul>
        </div>
      </div>}
      <h1>{title} <a className='plus' onClick={() => setOpen(not)}>+</a></h1>
      {desc}
      {open &&
        <TagInput
          placeholder={`Add something to "${tag}"`}
          ignoreLowercase={ignoreLowercase}
          onChange={from => {
            setAddTag(append({
              to: tag,
              from
            }))
          }}
        />}
      <ul className='tags'>
        {tags.map(t => {
          return (
            <li key={t.tag}>
              <Tag
                tag={t}
                onYes={() => {
                  if (t.me === '+') return
                  setAddTag(append({
                    to: tag,
                    from: t.tag,
                    yesNo: '+'
                  }))
                }}
                onNo={() => {
                  if (t.me === '-') return
                  setAddTag(append({
                    to: tag,
                    from: t.tag,
                    yesNo: '-'
                  }))
                }}
                link={!depth1}
              />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default See
