import { useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { icFileServerUserState, icConnectedState, icFileServerUrlState } from './state'
import './IcFileServerMessage.scss'


export default function IcFileServerMessage () {
  const icConnected = useRecoilValue(icConnectedState)
  const [icFileServerUrl, setIcFileServerUrl] = useRecoilState(icFileServerUrlState)
  const [icFileServerUser, setIcFileServerUser] = useRecoilState(icFileServerUserState)
  const [icFileServerUrlInput, setIcFileServerUrlInput] = useState('https://ic-file-server-tmm4c.ondigitalocean.app/ic')
  const [username, setUsername] = useState('')
  if (!icConnected || (icFileServerUrl && icFileServerUser)) return null
  let show = (
    <>
      You haven't connected to an IC file server. (Don't worry you can use mine.)
      <input type='text' value={icFileServerUrlInput} onChange={e => setIcFileServerUrlInput(e.target.value)} />
      <button className='btn' onClick={() => setIcFileServerUrl(icFileServerUrlInput)}>Ok</button>
    </>
  )
  if (icFileServerUrl) {
    show = (
      <>
        What's your username?
        <input type='text' value={username} onChange={e => setUsername(e.target.value)} />
        <button className='btn' onClick={() => setIcFileServerUser(username)}>Ok</button>
      </>
    )
  }
  return (
    <div className='ic-file-server-message'>
      {show}
    </div>
  )
}
